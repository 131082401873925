@import "../global.scss";

.interests {
  background-color: #091c34;
  //   background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 50px;
    color: white;

    @include mobile {
      font-size: 20px;
    }
  }
  .container {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 1rem;
    @include mobile {
      width: 70%;
    }
    .card {
      width: 80%;
      height: 30px;
      border-radius: 20px;
      background-color: white;
      margin: 3%;
      padding: 10%;
      transition: all 0.5s ease;
      h2 {
        font-size: 1rem;
      }
      &:hover {
        transform: scale(1.1);
      }
      @include mobile {
        width: 70%;
        height: 50px;
      }
    }
  }
}
