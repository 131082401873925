@import "../global.scss";

.education {
  // background-color: #161625;
  // background-image: linear-gradient(to bottom, #161625, #1b1b2b, #212132, #262638, #2c2c3f);
  // background-image: linear-gradient(to bottom, #19192a, #171a2e, #141b32, #0f1c37, #061d3b);
  background-image: linear-gradient(to bottom, #19192a, #161828, #131725, #101622, #0e151f);

  color: white;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  @include mobile {
    justify-content: space-around;
  }

  h1 {
    font-size: 50px;
    margin-top: 10px;
    @include mobile {
      font-size: 20px;
    }
  }

  .container1 {
    width: 100%;
    height: 80%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mobile {
      flex-direction: column;
      height: 100%;
    }

    .card1 {
      width: 300px;
      height: 200px;
      border-radius: 10px;
      box-shadow: 0px 0px 15px -8px whitesmoke;
      background-color: white;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 1s ease;
      margin: 0 10px;
      @include mobile {
        width: 200px;
        height: 140px;
        margin: 10px 0;
      }

      //   &.featured {
      //     width: 300px;
      //     height: 75%;
      //     margin: 0 30px;

      //     @include mobile {
      //       width: 250px;
      //       height: 180px;
      //       margin: 1px;
      //     }
      //   }

      &:hover {
        transform: scale(1.1);
      }

      .top1 {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .center1 {
        padding: 3px;
        margin-bottom: 10px;
        border-radius: 10px;
        background-color: rgb(250, 244, 245);

        @include mobile {
          font-size: 12px;
          //   padding: 5px;
        }
      }
      .bottom1 {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h3 {
          margin-bottom: 5px;
          font-size: medium;
          @include mobile {
            font-size: 14px;
            margin-bottom: 0%;
          }
        }
        h4 {
          color: rgb(121, 115, 115);
          font-size: medium;
          @include mobile {
            font-size: 13px;
            margin-bottom: 0%;
          }
        }
      }
    }
  }
}
