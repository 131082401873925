@import "../global.scss";

.portfolio {
  // background-color: #091c34;
  background-image: linear-gradient(
    to bottom,
    #091c34,
    #071c36,
    #041b38,
    #021b39,
    #001a3b
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  scrollbar-width: none;

  h1 {
    scrollbar-width: none;

    font-size: 50px;
    margin-top: 10px;
    @include mobile {
      font-size: 20px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      width: 100%;
    }
    scrollbar-width: none;
    .item {
      width: 550px;
      height: 300px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      // flex-direction: column;
      color: white;
      background-color: rgba(11, 8, 190, 0.616);
      position: relative;
      transition: all 0.5s ease;
      // transition: height 2s ease;
      // cursor: pointer;
      scrollbar-width: none;
      @include mobile {
        flex-direction: column;
        width: 100%;
        height: 200px;
      }

      h3 {
        position: absolute;
        font-size: 20px;
        top: 10px;
      }
      p {
        position: absolute;
        padding: 10px;
        span {
          background-color: rgba(11, 8, 190, 0.616);
          border-radius: 5px;
          padding: 3px;
          @include mobile {
            font-size: small;
          }
        }
      }

      h5 {
        position: absolute;
        font-size: 10px;
        color: yellow;
      }
      .stack {
        display: flex;
        position: absolute;
        margin-top: 35%;
        justify-content: space-around;
        width: 100%;
        @include mobile {
          flex-direction: column;
          margin-top: 70%;
        }
        .stack-item {
          // text-align: center;
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          color: white;
          border-radius: 10px;
          margin: 1px;
          margin-bottom: 2px;
          background-color: blueviolet;
          font-size: 12px;
          &:hover {
            background-color: red;
            color: white;
          }
        }
        .stack-item1 {
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          color: white;
          border-radius: 10px;
          margin: 1px;
          margin-bottom: 2px;
          background-color: blue;
          font-size: 12px;
          &:hover {
            background-color: red;
            color: white;
          }
        }
        .stack-item2 {
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          color: white;
          border-radius: 10px;
          margin: 1px;
          margin-bottom: 2px;
          background-color: green;
          font-size: 12px;
          &:hover {
            background-color: red;
            color: white;
          }
        }
        .stack-item3 {
          display: flex;
          align-items: center;
          padding-left: 5px;
          padding-right: 5px;
          color: white;
          border-radius: 10px;
          margin: 1px;
          margin-bottom: 2px;
          background-color: black;
          font-size: 12px;
          &:hover {
            background-color: red;
            color: white;
          }
        }
      }
      .link {
        display: flex;
        position: absolute;
        margin-top: 50%;
        // margin-right: 40%;
        justify-content: space-between;
        width: 60%;

        a {
          color: yellow;
          // z-index: 1;
          &:hover {
            color: white;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        border-radius: 20px;
      }

      &:hover {
        background-color: $mainColor;
        // color: white;
        height: auto;
        min-height: 310px;

        @include mobile {
          height: 80vh;
          p {
            top: 50px;
          }
          .link {
            margin-top: 120%;
          }
        }
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}
