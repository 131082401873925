@import "../global.scss";

.intro {
  // background-color: #091c34;
  background-image: linear-gradient(
    to bottom,
    #070422,
    #080c26,
    #09132b,
    #081830,
    #091c34
  );
  display: flex;
  overflow: hidden;
  color: white;
  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .left {
    flex: 0.5;
    overflow: hidden;
    align-items: center;
    .imgContainer {
      margin-top: 10%;
      // margin-right: 10%;
      width: auto;
      height: 450px;
      // background-color: #070422;
      // border-radius: 20%;
      // display: flex;
      // align-items: center;
      // justify-content: space-evenly;
      // float: left;
      // color: dodgerblue;
      @include mobile {
        align-items: flex-start;
        justify-content: center;
        display: none;
        // padding-top: 10%;
        // margin-bottom: 30%;
      }
      @include tablet {
        align-items: flex-start;
        justify-content: center;
        display: none;
        // padding-top: 10%;
        // margin-bottom: 30%;
      }
      // &:hover {
      //   color: "white";
      // }
      a {
        color: white;
        &:hover {
          color: aqua;
        }
      }
      img {
        height: 90%;
        // height: 50%;
        @include mobile {
          height: 50%;
        }
      }
    }
  }
  .right {
    flex: 0.5;
    position: relative;

    .wrapper {
      width: 100%;
      height: 100%;
      padding-left: 20%;
      padding-right: 0%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding-left: 0;
        padding-top: 10%;
        align-items: center;
        justify-content: space-between;
      }
      .connect {
        display: flex;
        justify-content: space-evenly;
        float: left;
        width: 100%;
        align-items: flex-start;
        a {
          position: static;
        }
        padding: 5%;
        .button1 {
          background-color: yellowgreen;
          color: white;
          text-decoration: none;
          padding: 2%;
          border-radius: 10px;
        }
        @include mobile {
          // align-items: center;
          justify-content: center;
          margin-left: 0%;
          padding: 0%;
          a {
            margin: 20px;
          }
        }
      }

      h1 {
        font-size: 60px;
        margin: 10px 0;

        @include mobile {
          font-size: 40px;
        }
      }
      h2 {
        font-size: 35px;
      }
      p {
        margin: 0%;
        padding-top: 10%;

        @include mobile {
          padding: 10%;
          // font-size: 20px;
        }
      }
      h3 {
        font-size: 30px;
        color: rgb(0, 153, 255);
        @include mobile {
          font-size: 20px;
        }

        span {
          font-size: inherit;
          // color: crimson;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 58%;
      color: white;
      &:hover {
        color: yellowgreen;
      }
      @include mobile {
        left: 45%;
        bottom: 5px;
      }
      img {
        width: 30px;
        animation: arrowBlink 2s infinite;
      }

      @keyframes arrowBlink {
        100% {
          opacity: 0;
        }
      }
    }
  }
}
