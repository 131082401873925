@import "../global.scss";

.skills {
  background-color: #161625;
  // background-image: linear-gradient(to bottom, #161625, #151729, #12192c, #0f1a30, #091c34);
  background-image: linear-gradient(
    to bottom,
    #19192a,
    #161828,
    #131725,
    #101622,
    #0e151f
  );
  // background-image: linear-gradient(to bottom, #19192a, #171a2e, #141b32, #0f1c37, #061d3b);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  h1 {
    font-size: 50px;
    margin-top: 10px;
    @include mobile {
      font-size: 20px;
    }
  }
  h5 {
    font-size: x-large;
    font-family: "Dongle", sans-serif;
    @include mobile {
      font-size: larger;
    }
  }
  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: "100%";
    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    // width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    @include mobile {
      width: 100%;
    }
    // scrollbar-width: none;
    .icon-class {
      height: 120px;
      // scrollbar-width: none;
      padding: 10px;
      @include mobile {
        height: 80px;
      }
      &:hover {
        transform: scale(0.9);
      }
    }
  }
}
