@import "../global.scss";

.contact {
  // background-color: #161625;
  // background-color: #091c34;
  background-image: linear-gradient(
    to bottom,
    #091c34,
    #071c36,
    #041b38,
    #021b39,
    #001a3b
  );
  display: flex;
  justify-content: center;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }

  .left {
    height: 80%;
    width: 200px;
    margin-left: 10%;
    background-color: white;
    border-radius: 30px;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      color: crimson;
      font-size: 2rem;
      padding: 2%;
      @include mobile {
        padding: 0%;
      }
    }
    h2 {
      padding-left: 10%;
      padding-right: 10%;
      color: darkblue;
      font-size: 1rem;
    }
    @include mobile {
      margin-top: 10px;
      margin-bottom: 10px;
      height: 50%;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    .cont {
      display: flex;
      flex-direction: column;
      justify-content: start;
      .itemContainer {
        display: flex;
        align-items: center;
        // margin-left: 30px;
        // padding-top: 2%;
        padding-bottom: 2%;
        @include mobile {
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;
          padding: 0%;
        }
        &:hover {
          color: crimson;
        }
        .icon {
          font-size: 18px;
          margin-right: 5px;
        }
        span {
          // color: green;

          a {
            text-decoration: none;
            color: black;
            &:hover {
              color: crimson;
            }
          }
        }
      }
    }
  }
  .right {
    flex: 1;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 3%;
    h2 {
      font-size: 30px;
      @include mobile {
        padding: 2%;
      }
    }
    // @include mobile {
    //   height: 100vh;
    // }
    form {
      width: 70%;
      // height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      // input {
      //   width: 300px;
      //   height: 30px;
      //   font-size: 14px;
      //   margin-bottom: 10px;
      //   @include mobile {
      //     width: 200px;
      //     height: 20px;
      //   }
      // }

      textarea {
        width: 300px;
        height: 200px;
        font-size: 14px;

        @include mobile {
          // width: 200px;
          // height: 100px;
        }
      }

      button {
        width: 150px;
        height: 30px;
        color: white;
        background-color: crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }

      span {
        color: green;
        a {
          text-decoration: none;
        }
      }
    }
  }
}
